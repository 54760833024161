<template>
  <nuxt-link
    :to="path"
    :class="$style.root"
  >
    <slot />
  </nuxt-link>
</template>

<script setup lang="ts">
const localePath = useLocalePath()

const props = defineProps({
  to: {
    type: [String, Object]
  },
  locale: {
    type: String,
    optional: true
  }
})

// NOTE: revert before pushing live
const path = computed(() => {
  const to = props.to instanceof Object ? {
    ...props.to,
    query: {
      ...props.to?.query
    }
  } : props.to
  return localePath(to, props.locale)
})
</script>

<style module>
.root[disabled] {
  pointer-events: none;
}
</style>
