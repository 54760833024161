<template>
  <I18nLink
    :to="routeObject"
    :locale="locale"
  >
    <slot />
  </I18nLink>
</template>

<script setup>
const { locale: currentLocale, locales } = useI18n()

const route = useRoute()
const props = defineProps({
  name: String,
  localizedData: Object,
  type: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'dynamic'].includes(value)
  }
})

const slug = computed(() => i18nGetLocalizedString(props?.localizedData?.slugs, 'i18n_slug_en') || props?.localizedData?.slug )
const locale = computed(() => {
  if (props?.localizedData?.slugs && !props?.localizedData?.slugs?.current_locale && props?.localizedData?.routeReference?.slug) {
    return 'en'
  } else {
    return currentLocale.value
  }
})

const params = computed(() => {
  return {
    slug: slug.value
  }
})

const routeObject = computed(() => {
  if ( props?.type === 'default') {
    return {
      name: props?.name,
      params: params.value,
      query: route?.query
    }
  } else {
    return {
      query: {
        ...route?.query,
        page: props?.name,
        slug: slug.value
      }
    }
  }
})
</script>
